import Head from 'next/head';

const Versioning = () => {
  return (
    <Head>
      <meta name="version" content="1.1.3.49418" />
      <meta name="build-date" content="2025-01-23T21:03:07Z" />
    </Head>
  );
};

export default Versioning;
